import React from 'react';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import Icon from '../../components/icon/icon.component';
import { Button } from 'antd';

const Header = (props) => {

  const { isMap } = props;

  const HeaderContainer = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: ${isMap ? '50%' : '100%'};
    height: 50px;
    padding: ${globalStyles.padding.default};
    z-index: 1;
    background-color: 'white';
    position: ${isMap ? 'absolute' : 'relative'};
    top: 0;
    right: 0;
  `;

  return (
    <HeaderContainer>
      <Email href='mailto:info@allianceforfertilitypreservation.org'>
        {!isMap && (
          <React.Fragment>
            <Icon type='email' />
            <EmailText>info@allianceforfertilitypreservation.org</EmailText>
          </React.Fragment>
        )}
      </Email>
      <RightContainer>
        <Icon type='facebook' hover={true} padding='0 1rem 0 0' link='https://www.facebook.com/allianceforFP/' />
        <Icon type='twitter' hover={true} padding='0 1rem 0 0' link='https://twitter.com/AllianceforFP' />
        <Icon type='instagram' hover={true} padding='0 1rem 0 0' link='https://www.instagram.com/allianceforfp/' />
        <Button
          type='default'
          shape='round'
          style={{ backgroundColor: globalStyles.color.afpGreen, fontWeight: 'bold' }}
          href='https://www.allianceforfertilitypreservation.org/become-a-supporter'
          target='_blank'
        >
          Donate
      </Button>
      </RightContainer>
    </HeaderContainer>
  )
}

export default Header;

const RightContainer = styled.div`
  font-size: 1rem;
  display: flex;
  align-items: center;
`;

const Email = styled.a`
  font-size: 1rem;
  color: black;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: ${globalStyles.color.afpDarkBlue};
  }
`;

const EmailText = styled.span`
  padding-left: .5rem;
`;