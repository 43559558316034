const globalStyles = {
  color: {
    afpBlue: '#7BA0CD',
    afpGreen: '#ACD693',
    afpGrey: '#F0F0F0',
    afpDarkBlue: '#3282CD',
  },
  breakpoints: {
    mobile: '(max-width: 550px)',
    tablet: '(max-width: 768px)',
    desktop: '(max-width: 1024px)',
    l_desktop: '(max-width: 2000px)',
  },
  padding: {
    default: '0 10px',
  },
  border: {
    default: `1px solid #D3D3D3`,
  }
}

export default globalStyles;