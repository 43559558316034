import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

let autoComplete;

const CurrentLocationInput = (props) => {

  const {
    padding,
    setGeolocation,
    placeholder,
    minWidth,
  } = props;

  const autoCompleteRef = useRef(null);
  const [query, setQuery] = useState('');

  useEffect(() => {
    handleScriptLoad(setQuery, autoCompleteRef)
  }, []);

  const handleScriptLoad = (updateQuery, autoCompleteRef) => {
    autoComplete = new window.google.maps.places.Autocomplete(
      autoCompleteRef.current,
      { componentRestrictions: { country: 'us' } }
    );
    autoComplete.setFields(["formatted_address", "geometry"]);
    autoComplete.addListener("place_changed", () => handlePlaceSelect(updateQuery));
  }

  const handlePlaceSelect = (setQuery) => {
    const addressObject = autoComplete.getPlace();
    const formatted_address = addressObject.formatted_address;

    setQuery(formatted_address);

    if (addressObject.formatted_address !== undefined) {
      const lat = addressObject.geometry.location.lat();
      const lng = addressObject.geometry.location.lng();
      setGeolocation({ lat: lat, lng: lng });
    }
  }

  return (
    <PlacesSearchContainer style={{minWidth: minWidth, padding: padding}}>
      <PlacesInput
        ref={autoCompleteRef}
        onChange={event => setQuery(event.target.value)}
        placeholder={placeholder}
        value={query}
      />
    </PlacesSearchContainer>
  );
}

const PlacesSearchContainer = styled.div`
  width: 100%;
`;

const PlacesInput = styled.input`
  width: inherit;
  padding: 4px 10px;
  border: 1px solid #D4D4D4;

  &::placeholder {
    color: #ABABAB;
    font-size: 14px;
  }

  &:focus {
    border-color: #57a8e9;
    box-shadow: 0 1px 1px #57a8e9 inset, 0 0 2px #57a8e9;
    outline: 0 none;
  } 
`;

CurrentLocationInput.defaultProps = {
  padding: '0',
}

CurrentLocationInput.propTypes = {
  padding: PropTypes.string,
}

export default CurrentLocationInput;