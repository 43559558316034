import React, { useContext } from 'react';
import { Context } from '../../state/store.state';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import { Steps } from 'antd';

import Landing from '../landing/landing.container';
import SearchService from '../search/search-service.container';
import SearchMadlibs from '../search/search-madlibs.container';

const Content = (props) => {

  const {
    goMap,
    isLoading,
    isMobileWidth,
  } = props;

  const [globalState, dispatch] = useContext(Context);

  const onStepperChange = current => {
    dispatch({ type: 'SET_STEP', payload: current });
  };

  const getStepContent = () => {
    let stepContent = null;
    switch (globalState.currentStep) {
      case 0:
        stepContent = <Landing onStepperChange={onStepperChange} isLoading={isLoading}/>;
        break;
      case 1:
        stepContent = <SearchService onStepperChange={onStepperChange} goMap={goMap} />
        break;
      case 2:
        stepContent = <SearchMadlibs onStepperChange={onStepperChange} goMap={goMap} isMobileWidth={isMobileWidth}/>
        break;
      default:
        stepContent = null;
        break;
    }
    return stepContent;
  }

  return (
    <ContentContainer >
      <Steps current={globalState.currentStep} onChange={onStepperChange} type='navigation' />
      <StepContentContainer>
        {getStepContent()}
      </StepContentContainer>
    </ContentContainer>
  )
}

export default Content;

const ContentContainer = styled.section`
  flex: auto;
  background-color: ${globalStyles.color.afpGrey};
  border-top: ${globalStyles.border.default};
`;

const StepContentContainer = styled.div`

`;