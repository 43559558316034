import React from 'react';
import styled from 'styled-components';
import afp_member from '../../assets/img/afp_member.png';
import { Tooltip } from 'antd';

const PanelHeader = ({card}) => (
  <PanelHeaderContainer>
    <LeftContainer>
      {card._approve_for_email === 'true' && (
        <Tooltip 
          placement='right'
          overlayStyle={{minWidth: '580px'}}
          color='black'
          title="AFP members are dedicated to meeting our patient's needs. Through Fertility Scout, they have an exclusive, secure channel for consultation requests or direct contact form. To enroll, please contact our Program Manager, Meghan Bowman, at meghan@allianceforfertilitypreservation.org"
        >
          <AFPMember src={afp_member} />
        </Tooltip>
      )}
      <Title>{card.clinic_practice_name} - {card.city} </Title>
    </LeftContainer>
    <RightContainer>
      <Miles>{card.distance < 999999 ? `${card.distance.toFixed(2)} mi` : null}</Miles>
    </RightContainer>
  </PanelHeaderContainer>
)

export default PanelHeader;

const PanelHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
  background-color: '#DBE4F3';
`;

const LeftContainer = styled.div`
  display: flex;
`;

const RightContainer = styled.div`
  display: flex;
`;

const Title = styled.span`
  font-weight: 600;
  display: flex;
`;

const AFPMember = styled.img`
  height: 60px;
  padding-right: 10px;
`;

const Miles = styled.span`
  min-width: 70px;
  padding-left: 5px;
  display: flex;
  justify-content: flex-end;
`;