import React from 'react';
import globalStyles from '../../assets/style/global.style';
import { Divider } from 'antd';
import styled from 'styled-components';

import Text from '../../components/text/text.component';
import MiniIcon from '../../assets/img/fs_mini_icon.png';
import Icon from '../../components/icon/icon.component';

const SearchHeader = (props) => {
    const getStep = () => {
        const step = props.type === 'quicksearch' ? 0 : 1;
        return step;
    };

    return (
        <React.Fragment>
            <BackContainer>
                <Text
                    color={globalStyles.color.afpBlue}
                    hoverColor={globalStyles.color.afpDarkBlue}
                    fontSize='1rem'
                    fontWeight='700'
                    cursor='pointer'
                    onClick={() => props.onStepperChange(getStep())}
                >
                    <Icon
                        type='leftchevron'
                        color={globalStyles.color.afpBlue}
                        fontWeight='700'
                    />
                    Go back
                </Text>
            </BackContainer>
            <img src={MiniIcon} alt='mini icon' />
            {props.type === 'madlibs' ? (
                <Text
                    color={globalStyles.color.afpBlue}
                    hoverColor={globalStyles.color.afpDarkBlue}
                    fontSize='1.4rem'
                    mobileFontSize='1.1rem'
                    fontWeight='700'
                    cursor='pointer'
                    onClick={() => props.onStepperChange(1)}
                >
                    Back to quick search? Click here!
                </Text>
            ) : null}
            <Divider>
                <Text
                    color={globalStyles.color.afpBlue}
                    fontWeight='700'
                    fontSize='1.3rem'
                >
                    {props.type === 'madlibs' ? null : 'Quick Search'}
                </Text>
            </Divider>
        </React.Fragment>
    );
};

export default SearchHeader;

const BackContainer = styled.div`
    position: absolute;
    top: 60px;
    left: 10px;
`;

const InfoContainer = styled.div`
    position: absolute;
    top: 60px;
    right: 10px;

    @media ${globalStyles.breakpoints.mobile} {
        margin-bottom: 20px;
    }
`;

const InfoText = styled.span`
    @media ${globalStyles.breakpoints.mobile} {
        display: none;
    }
`;

const InfoTextMobile = styled.span`
    display: none;
    @media ${globalStyles.breakpoints.mobile} {
        display: block;
        padding-right: 5px;
    }
`;
