import React from 'react';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';

const Footer = () => (
  <FooterContainer>
    © 2020 Alliance for Fertility Preservation. All rights reserved. Tel: (925) 290-8950
  </FooterContainer>
)

export default Footer;

const FooterContainer = styled.footer`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;
  color: white;
  font-weight: bold;
  background-color: ${globalStyles.color.afpBlue};

  @media ${globalStyles.breakpoints.mobile} {
    font-size: .8rem;
    font-weight: normal;
    padding: ${globalStyles.padding.default};
  }
`;