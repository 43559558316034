import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../state/store.state';
import styled from 'styled-components';

import MapSearch from './map-search.container';
import Map from './map.container';

const Result = (props) => {

  const [zoom, setZoom] = useState(12);
  const [center, setCenter] = useState({});
  const [cards, setCards] = useState([]);
  const [activeMarkerIndex, setActiveMarkerIndex] = useState(null);
  const [userCenter, setUserCenter] = useState({});
  const [accordionIndex, setAccordionIndex] = useState(null);

  const [globalState, dispatch] = useContext(Context);

  useEffect(() => {
    if (globalState.geolocation) setCenter({ lat: globalState.geolocation.lat, lng: globalState.geolocation.lng });
    
    const filteredForApproveCards = globalState.cards.filter(card => card._approve === 'true');
    setCards(filteredForApproveCards);
  }, []);

  const setGeolocation = (coords) => {
    setCenter(coords);
    setUserCenter(coords);
  }

  return (
    <React.Fragment>
      {(cards.length && center) && (
        <MapContainer>
          <Map
            zoom={zoom}
            center={center}
            setCenter={setCenter}
            cards={cards}
            setActiveMarkerIndex={setActiveMarkerIndex}
            accordionIndex={accordionIndex}
          />
          <MapSearch 
            {...props} 
            globalState={globalState} 
            center={center} 
            cards={cards} 
            setCenter={setCenter} 
            activeMarkerIndex={activeMarkerIndex}
            setActiveMarkerIndex={setActiveMarkerIndex}
            userCenter={userCenter}
            setAccordionIndex={setAccordionIndex}
          />
        </MapContainer>
      )}
    </React.Fragment>
  )
}

const MapContainer = styled.div`
  display: flex;
  height: 100vh;
`;

export default Result;