import styled, { css } from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import PropTypes from 'prop-types';
import {
  MailOutlined,
  FacebookOutlined,
  TwitterOutlined,
  InstagramOutlined,
  SearchOutlined,
  ArrowRightOutlined,
  SolutionOutlined,
  MessageOutlined,
  UserAddOutlined,
  UnorderedListOutlined,
  LeftOutlined,
  InfoCircleOutlined,
  PushpinOutlined,
  LoadingOutlined,
  RightOutlined,
} from '@ant-design/icons';
import MarkerIcon from '../../assets/img/marker.svg';

// Icon Reference => https://ant.design/components/icon/

const Icon = (props) => {
  const {
    type,
    hover,
    padding,
    link,
    fontSize,
    cursor,
    color,
    fontWeight,
  } = props;

  let IconComponent = null;

  switch(type) {
    case 'email':
      IconComponent = <MailOutlined />;
      break;
    case 'facebook':
      IconComponent = <FacebookOutlined />;
      break;
    case 'twitter':
      IconComponent = <TwitterOutlined />;
      break;
    case 'instagram':
      IconComponent = <InstagramOutlined />;
      break;
    case 'useradd':
      IconComponent = <UserAddOutlined />;
      break;
    case 'search':
      IconComponent = <SearchOutlined />;
      break;
    case 'solution':
      IconComponent = <SolutionOutlined />;
      break;
    case 'arrow':
      IconComponent = <ArrowRightOutlined />;
      break;  
    case 'message':
      IconComponent = <MessageOutlined />;
      break;
    case 'service':
      IconComponent = <UnorderedListOutlined />;
      break;
    case 'leftchevron':
      IconComponent = <LeftOutlined />;
      break;
    case 'rightchevron':
      IconComponent = <RightOutlined />;
      break;
    case 'info':
      IconComponent = <InfoCircleOutlined />;
      break;
    case 'pin':
      IconComponent = <PushpinOutlined />;
      break;
    case 'loading':
      IconComponent = <LoadingOutlined />;
      break;
    case 'marker':
      IconComponent = <Icon component={<img src={MarkerIcon} alt={`${MarkerIcon}-icon`} />} />
      break;
    default:
      IconComponent = null;
      break;
  }

  const BaseIconStyle = css`
    font-size: ${fontSize ? fontSize : '1rem'};
    cursor: ${cursor};
    padding: ${padding};
    color: ${color};
    font-weight: ${fontWeight}
  `;

  const IconStyleA = styled.a`
    ${BaseIconStyle}
    text-decoration: none;
    &:hover {
      color: ${hover ? globalStyles.color.afpDarkBlue : null};
    }
  `;

  const IconStyle = styled.span`
    ${BaseIconStyle}
    &:hover {
      color: ${hover ? globalStyles.color.afpDarkBlue : null};
    }
  `;

  return link 
    ? <IconStyleA href={link} target='_blank' rel='noreferrer'>{IconComponent}</IconStyleA>  
    : <IconStyle>{IconComponent}</IconStyle> 
}

Icon.defaultProps = {
  type: null,
  hover: null,
  padding: null,
  link: null,
  fontSize: null,
  cursor: 'pointer',
  color: 'black',
  fontWeight: null,
}

Icon.propTypes = {
  type: PropTypes.string,
  hover: PropTypes.bool,
  padding: PropTypes.string,
  link: PropTypes.string,
  fontSize: PropTypes.string,
  cursor: PropTypes.string,
  color: PropTypes.string,
  fontWeight: PropTypes.string,
}

export default Icon;