import axios from 'axios';

const clinicMessage = 'You are receiving a secure request for a Consultation through the Fertility Scout Tool. \nPlease see below information applicable to the individual wishing to receive consultation contact.';

const individMessage = 'You are receiving a secure Direct Connection Request through the Fertility Scout Tool. Please see below information applicable to the individual wishing to receive direct contact.';

const createMessage = (template, first, last, phone, email, message) => {
  return template + '\nFirst name: ' + first + '\nLast name: ' + last + '\nEmail: ' + email + '\nPhone number: ' + phone + '\nMessage: ' + message;
};

export const sendEmailRequest = async (receiver, type, first, last, phone, email, message) => {
  const template = (type === 'clinic') ? clinicMessage : individMessage;
  const setTemplate = createMessage(template, first, last, phone, email, message);

  return await axios({
    url: process.env.REACT_APP_EMAIL_SERVER_URL,
    method: 'POST',
    headers: {
      'x-api-key': process.env.REACT_APP_EMAIL_SERVER_KEY
    },
    data: {
      'email': receiver,
      'subject': 'FS Contact Request',
      'content': setTemplate
    }
  })
    .then(() => {})
    .catch(err => console.log(err));
}