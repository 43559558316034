import React, { useState, useContext, useEffect } from 'react';
import { Context } from '../../state/store.state';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import { Select, Button } from 'antd';

import Text from '../../components/text/text.component';
import Icon from '../../components/icon/icon.component';
import SearchHeader from './search-header.container';
import { services } from './search-madlibs-service.data';
import CurrentLocationInput from '../../components/current-location-input/current-location-input.component';
import { filterServices, filterNames } from '../../util/services.util';

const { Option } = Select;

const SearchService = (props) => {
    const [globalState, dispatch] = useContext(Context);
    const [selectedServices, setSelectedServices] = useState([]);
    const [selectedNames, setSelectedNames] = useState([]);
    const [notFound, setNotFound] = useState('');
    const [isSearchDisabled, setIsSearchDisabled] = useState(true);

    useEffect(() => {
        !globalState.geolocation
            ? setIsSearchDisabled(true)
            : setIsSearchDisabled(false);
    }, []);

    const onSearch = () => {
        let cards = globalState.data;

        if (selectedServices.length)
            cards = filterServices(selectedServices, cards);

        if (selectedNames.length) cards = filterNames(selectedNames, cards);

        if (cards.length < 1) {
            setNotFound('No services found please try a different selection');
        } else {
            dispatch({ type: 'SET_CARDS', payload: cards });
            props.goMap();
        }
    };

    const onSetSelectedServices = (e) => {
        setSelectedServices(e);
        setNotFound('');
    };

    const onSetSelectedNames = (e) => {
        setSelectedNames(e);
        setNotFound('');
    };

    const setGeolocation = (coords) => {
        dispatch({
            type: 'SET_GEOLOCATION',
            payload: coords,
        });

        setIsSearchDisabled(false);
    };

    return (
        <SearchServiceContainer>
            <SearchHeader {...props} type={'quicksearch'} />
            <InputLabel>
                Select Services{' '}
                <LabelInstructions>
                    (Optional, leave blank for all services)
                </LabelInstructions>
                :
            </InputLabel>
            <Select
                mode='multiple'
                placeholder='Start Typing to Filter...'
                style={{ minWidth: '100%' }}
                dropdownMatchSelectWidth
                showArrow
                allowClear
                optionLabelProp='label'
                onChange={(e) => onSetSelectedServices(e)}
            >
                {services.map((service, index) => (
                    <Option
                        key={index}
                        value={service.value}
                        label={service.label}
                    >
                        <ServiceOption>
                            <strong>{service.label}</strong>
                            <br />
                            <small>{service.description}</small>
                        </ServiceOption>
                    </Option>
                ))}
            </Select>

            <InputLabel>
                Search by Doctor, Professional's Name, or Clinic Name{' '}
                <LabelInstructions>
                    (Optional, leave blank for all results):
                </LabelInstructions>
            </InputLabel>
            <Select
                mode='multiple'
                placeholder='Start Typing to Filter...'
                showArrow
                allowClear
                showSearch
                style={{ minWidth: '100%' }}
                dropdownMatchSelectWidth
                onChange={(e) => onSetSelectedNames(e)}
            >
                {globalState.searchableNames.map((names, index) => (
                    <Option key={index} value={names}>
                        {names}
                    </Option>
                ))}
            </Select>

            <InputLabel>
                Set your location by address, city, or zipcode{' '}
                <LabelInstructions>
                    (Required if location services is off)
                </LabelInstructions>
                :
            </InputLabel>
            <CurrentLocationInput
                setGeolocation={setGeolocation}
                placeholder='Uses your current location by default'
            />
            <Button
                type='default'
                shape='round'
                size='large'
                block
                style={{
                    backgroundColor: globalStyles.color.afpGreen,
                    fontWeight: 'bold',
                    marginTop: '20px',
                }}
                icon={<Icon type='search' color='white' fontSize='1.1rem' />}
                onClick={onSearch}
                disabled={isSearchDisabled}
            >
                <Text color='white' fontWeight='700' padding='0 0 0 5px'>
                    {isSearchDisabled ? 'Location required' : 'Search'}
                </Text>
            </Button>
            <Text color={'red'}>{notFound}</Text>
            <Text
                color={globalStyles.color.afpBlue}
                hoverColor={globalStyles.color.afpDarkBlue}
                fontSize='1.4rem'
                fontWeight='700'
                cursor='pointer'
                padding='20px 0 0 0'
                onClick={() => props.onStepperChange(2)}
                textAlign='center'
                mobileFontSize='1.1rem'
            >
                Not sure where to start? Click here!
            </Text>
            <Email href='mailto:info@allianceforfertilitypreservation.org'>
                <React.Fragment>
                    <Icon type='email' />
                    <EmailText>Feedback? Questions? Email us!</EmailText>
                </React.Fragment>
            </Email>
        </SearchServiceContainer>
    );
};

export default SearchService;

const SearchServiceContainer = styled.section`
    padding: 20px 20%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media ${globalStyles.breakpoints.mobile} {
        padding: 20px;
    }
`;

const InputLabel = styled.div`
    padding-top: 10px;
    padding-bottom: 2px;
    font-size: 15px;
    width: 100%;
    font-weight: bold;
`;

const LabelInstructions = styled.span`
    font-weight: normal;
    font-style: italic;
`;

const Email = styled.a`
    padding-top: 5px;
    font-size: 1rem;
    color: black;
    font-weight: 600;
    text-decoration: none;
    &:hover {
        color: ${globalStyles.color.afpDarkBlue};
    }
`;

const EmailText = styled.span`
    padding-left: 0.5rem;
`;

const ServiceOption = styled.div`
    white-space: pre-wrap;
    text-overflow: none;
`;
