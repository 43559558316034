import React from 'react';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import PropTypes from 'prop-types';

const Info = (props) => {
  const {
    icon,
    text,
    backgroundColor,
    margin,
  } = props;

  const InfoContainer = styled.div`
    display: flex;
    background-color: ${backgroundColor};
    margin: ${margin};
    border-radius: 10px;
    padding: 10px;

    @media ${globalStyles.breakpoints.mobile} {
      padding: 10px;
    }
  `;

  return (
    <InfoContainer>
      <IconContainer>{icon}</IconContainer>
      <TextContainer>{text}</TextContainer>
    </InfoContainer>
  )
}

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
  width: 10%;
`;

const TextContainer = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
`;

Info.defaultProps = {
  icon: null,
  text: null,
  backgroundColor: globalStyles.color.afpGreen,
  margin: '10px 0 0 0',
}

Info.propTypes = {
  icon: PropTypes.element.isRequired,
  text: PropTypes.element.isRequired,
  backgroundColor: PropTypes.string,
  margin: PropTypes.string,
}

export default Info;