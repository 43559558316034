import React, { useState, useEffect } from 'react'
import { GoogleMap, Marker, InfoBox } from '@react-google-maps/api';
import { calcCoordinateDistance } from '../../util/map.util';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';

function Map(props) {

  const {
    setActiveMarkerIndex,
    center,
    setCenter,
    zoom,
    cards,
    accordionIndex,
  } = props;

  const [formattedCards, setformattedCards] = useState([]);
  const [infoBoxData, setInfoBoxData] = useState(null);

  useEffect(() => {
    const formattedCards = sortCardsByDistance();
    setformattedCards(formattedCards);

    const currentAccordion = formattedCards[0];
    setInfoBoxData({
      lat: currentAccordion.latitude,
      lng: currentAccordion.longitude,
      title: `${currentAccordion.clinic_practice_name} - ${currentAccordion.city}`,
      street_address: currentAccordion.street_address,
      suite_floor: currentAccordion.suite_floor,
      city: currentAccordion.city,
      us_states: currentAccordion.us_states,
      zip: currentAccordion.zip
    });
  }, [cards]);

  useEffect(() => {
    if (accordionIndex || accordionIndex === 0) {
      const currentAccordion = formattedCards[accordionIndex];
      setMarkerInfoBox(currentAccordion.latitude, currentAccordion.longitude);
      setInfoBoxData({
        lat: currentAccordion.latitude,
        lng: currentAccordion.longitude,
        title: `${currentAccordion.clinic_practice_name} - ${currentAccordion.city}`,
        street_address: currentAccordion.street_address,
        suite_floor: currentAccordion.suite_floor,
        city: currentAccordion.city,
        us_states: currentAccordion.us_states,
        zip: currentAccordion.zip
      });
      setCenter({ lat: currentAccordion.latitude, lng: currentAccordion.longitude})
    }
  }, [accordionIndex]);

  const sortCardsByDistance = () => {
    let cardsSorted = cards.map(card => {
      let dist = (!card.latitude) ? 999999 : calcCoordinateDistance(center.lat, center.lng, card.latitude, card.longitude);
      return ({ ...card, distance: dist })
    })
    cardsSorted = cardsSorted.sort((a, b) => (a.distance > b.distance) ? 1 : -1);
    return cardsSorted;
  }

  const onMarkerClick = (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setActiveMarkerIndex(setMarkerInfoBox(lat, lng));
  }

  const setMarkerInfoBox = (lat, lng) => {
    let cardIndex = null;

    formattedCards.filter((card, index) => {
      if (card.latitude === lat && card.longitude === lng) {
        cardIndex = index;
      }
    });

    const currentCard = formattedCards[cardIndex];

    setInfoBoxData({
      lat: lat,
      lng: lng,
      title: `${currentCard.clinic_practice_name} - ${currentCard.city}`,
      street_address: currentCard.street_address,
      suite_floor: currentCard.suite_floor,
      city: currentCard.city,
      us_states: currentCard.us_states,
      zip: currentCard.zip
    });

    return cardIndex;
  }

  return (
    <GoogleMap
      mapContainerStyle={{
        width: '100vw',
        height: '100vh'
      }}
      center={center}
      zoom={zoom}
      options={{ fullscreenControl: false, mapTypeControl: false}}
    >
      {formattedCards.map((card, index) =>
        <Marker
          key={index}
          position={{ lat: parseFloat(card.latitude), lng: parseFloat(card.longitude) }}
          title={`${card.clinic_practice_name} - ${card.city}`}
          onClick={onMarkerClick}
        >
        </Marker>
      )}
      {infoBoxData && (
        <InfoBox
          options={{ closeBoxURL: '', enableEventPropagation: true }}
          position={{ lat: infoBoxData.lat, lng: infoBoxData.lng }}
          styles={{width: 'auto'}}
        >
          <MarkerInfoBox>
            <MarkerInfoBoxTitle>{infoBoxData.title}</MarkerInfoBoxTitle>
            <div>
              {infoBoxData.street_address} {infoBoxData.suite_floor} <br></br>
              {infoBoxData.city}, {infoBoxData.us_states} {infoBoxData.zip}
            </div>
          </MarkerInfoBox>
        </InfoBox>
      )}
    </GoogleMap>
  )
}

export default Map;

const MarkerInfoBox = styled.div`
  background: white;
  opacity: .9;
  padding: 10px;
  border: 1px solid ${globalStyles.color.afpDarkBlue};
  border-radius: 2px;
`;

const MarkerInfoBoxTitle = styled.div`
  font-weight: bold;
  padding-bottom: 4px;
`;