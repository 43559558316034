import React from 'react';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import { Divider } from 'antd';

import Text from '../../components/text/text.component';
import Icon from '../../components/icon/icon.component';
import Info from '../../components/info/info.component';
import MiniIcon from '../../assets/img/fs_mini_icon.png';

const instructions = [
  { text: '1. SEARCH for the service and location that you need; OR search by the name of the doctor, provider, or facility', iconType: 'search' },
  { text: '2. SELECT the doctor, provider, or facility that you wish to connect with and view information about', iconType: 'arrow'},
  { text: '3. CONTACT the facility directly, by calling or by using the secure online form to request a consultation or send a message', iconType: 'email'}
];

const Landing = (props) => (
  <LandingContainer>
    <LandingText>The <Text link='https://www.allianceforfertilitypreservation.org/' color={globalStyles.color.afpBlue} hoverColor={globalStyles.color.afpDarkBlue}>Alliance for Fertility Preservation</Text> created Fertility Scout to help you find fertility preservation and other family building services quickly</LandingText>
    <Divider><img src={MiniIcon} alt='mini icon'/></Divider>
    <LandingText>We understand that this can be a difficult and emotional time. Fertility Scout features <Text color={globalStyles.color.afpBlue}>three simple steps</Text> to connect you with healthcare and other providers that will best service your fertility and post-treatment reproductive needs:</LandingText>
    <LandingAction>
      <LandingInstructions>
        {instructions.map((eaInfo, index) => (
          <Info 
            key={index}
            icon={<Icon type={eaInfo.iconType} cursor={null} fontSize='2rem' color='white'/>}
            text={<Text color='white' fontWeight='600' fontSize='1rem'>{eaInfo.text}</Text>}
          />
        ))}
      </LandingInstructions>
    </LandingAction>
    <LandingBottom>
      {
        props.isLoading
          ? (<Text
            color={globalStyles.color.afpBlue}
            hoverColor={globalStyles.color.afpGreen}
            fontSize='2rem'
            cursor='pointer'
            fontWeight='700'
          >
            <Icon type='loading' fontSize='2rem' color={globalStyles.color.afpBlue}/> Loading...
          </Text>)
        : (
          <Text
            onClick={() => props.onStepperChange(1)}
            color={globalStyles.color.afpBlue}
            hoverColor={globalStyles.color.afpGreen}
            fontSize='2rem'
            textDecoration='underline'
            cursor='pointer'
            fontWeight='700'
          >
            Start Here
          </Text>
        )
      }
    </LandingBottom>
  </LandingContainer>
)

export default Landing;

const LandingContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${globalStyles.color.afpGrey};
  padding: 20px 20%;

  @media ${globalStyles.breakpoints.desktop} {
    padding: 20px 10%;
  }

  @media ${globalStyles.breakpoints.tablet} {
    padding: 20px 20px;
  }

  @media ${globalStyles.breakpoints.mobile} {
    padding: 10px 10px;
  }
`;

const LandingText = styled.div`
  font-size: 1.1rem;
  font-weight: 800;
  text-align: center;
`;

const LandingAction = styled.div`
  display: flex;
  flex: 1;
  padding-top: 10px;

  @media ${globalStyles.breakpoints.mobile} {
    flex-direction: column;
  }
`;

const LandingInstructions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const LandingBottom = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 1rem;
  
  @media ${globalStyles.breakpoints.mobile} {
    height: 140px;
  }
`;