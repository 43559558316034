
import React, { createContext, useReducer } from 'react';
import Reducer from './reducer.state';

const initialState = {
  data: null,
  cards: null,
  geolocation: null,
  currentStep: 0,
  searchableNames: null,
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>
      {children}
    </Context.Provider>
  )
};

export const Context = createContext(initialState);
export default Store;