const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_DATA':
      return {
        ...state,
        data: action.payload
      };
    case 'SET_CARDS':
      return {
        ...state,
        cards: action.payload
      };
    case 'SET_GEOLOCATION':
      return {
        ...state,
        geolocation: action.payload
      };
    case 'SET_STEP':
      return {
        ...state,
        currentStep: action.payload
      };
    case 'SET_SEARCHABLE_NAMES':
      return {
        ...state,
        searchableNames: action.payload
      };
    default:
      return state;
  }
};

export default Reducer;