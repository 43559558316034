import React from 'react';
import styled, { css } from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import PropTypes from 'prop-types';

const Text = (props) => {
  const {
    link,
    fontSize,
    textDecoration,
    cursor,
    fontWeight,
    padding,
    onClick,
    color,
    hoverColor,
    minWidth,
    margin,
    textAlign,
    height,
    fontStyle,
    mobileFontSize,
    mobileMinWidth,
    mobilePadding,
  } = props;

  const BaseTextStyle = css`
    color: ${color};
    font-size: ${fontSize};
    text-decoration: ${textDecoration};
    cursor: ${cursor};
    font-weight: ${fontWeight};
    padding: ${padding};
    min-width: ${minWidth};
    margin: ${margin};
    text-align: ${textAlign};
    height: ${height};
    font-style: ${fontStyle};
    
    &:hover {
      color: ${hoverColor}
    }

    @media ${globalStyles.breakpoints.mobile} {
      font-size: ${mobileFontSize};
      min-width: ${mobileMinWidth};
    }
  `;

  const TextA = styled.span`
    ${BaseTextStyle};
    text-decoration: none;
    cursor: pointer;
  `;

  const TextDefault = styled.span`
    ${BaseTextStyle};
  `;

  return (
    link
      ? <TextA onClick={onClick}>
          <a href={link} target='_blank' rel='noreferrer' style={{textDecoration: 'inherit', color: 'inherit'}}>
            {props.children}
          </a>
        </TextA >
      : <TextDefault onClick={onClick}>{props.children}</TextDefault>
  )
}

Text.defaultProps = {
  link: null,
  fontSize: null,
  textDecoration: null,
  cursor: null,
  fontWeight: null,
  padding: null,
  color: null,
  hoverColor: null,
  margin: null,
  textAlign: null,
  onClick: () => {},
  height: null,
  fontStyle: null,
  mobileFontSize: null,
  mobileMinWidth: null,
  mobilePadding: null,
}

Text.propTypes = {
  link: PropTypes.string,
  fontSize: PropTypes.string,
  textDecoration: PropTypes.string,
  cursor: PropTypes.string,
  fontWeight: PropTypes.string,
  padding: PropTypes.string,
  color: PropTypes.string,
  hoverColor: PropTypes.string,
  onClick: PropTypes.func,
  minWidth: PropTypes.string,
  margin: PropTypes.string,
  textAlign: PropTypes.string,
  height: PropTypes.string,
  fontStyle: PropTypes.string,
  mobileFontSize: PropTypes.string,
  mobileMinWidth: PropTypes.string,
  mobilePadding: PropTypes.string,
}

export default Text;