import { services } from '../containers/search/search-madlibs-service.data';

const servicesLabelsMap = new Map(
    services.reduce((acc, service) => {
        acc.push([service.value, service.label]);
        return acc;
    }, [])
);
const servicesMapper = (service) => {
    return servicesLabelsMap.get(service);
};

const filterServices = (selection, cards) => {
    const filteredCards = cards.filter((eaCard) => {
        if (eaCard.services !== undefined) {
            if (
                eaCard.services.some((service) => selection.includes(service))
            ) {
                return eaCard;
            }
        }
    });

    return filteredCards;
};

const filterNames = (selection, cards) => {
    const filteredCards = cards.filter((eaCard) => {
        if (
            eaCard.clinic_practice_name !== undefined ||
            eaCard.doctor !== undefined
        ) {
            if (
                selection.includes(eaCard.clinic_practice_name) ||
                selection.includes(eaCard.doctor)
            ) {
                return eaCard;
            }
        }
    });

    return filteredCards;
};

export { servicesMapper, filterServices, filterNames };
