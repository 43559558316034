import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Form, Input } from 'antd';
import { sendEmailRequest } from '../../api/email.api';

const MapModal = (props) => {

  const {
    onModalButtonClick,
    cardEmail,
    name,
    type,
    modalVisible,
  } = props;

  const [modalContactButton, setModalContactButton] = useState(true);
  const [form] = Form.useForm();
  
  const onSendContactRequest = () => {
    const first = form.getFieldValue('firstname');
    const last = form.getFieldValue('lastname');
    const phone = form.getFieldValue('phone');
    const email = form.getFieldValue('email');
    const message = form.getFieldValue('message');
    const receiver = cardEmail;

    sendEmailRequest(receiver, type, first, last, phone, email, message);
    
    // Reset Modal Form Fields
    form.resetFields();

    onModalButtonClick();
  }

  const onFormValuesChange = () => {
    const first = form.getFieldValue('firstname');
    const last = form.getFieldValue('firstname');
    const phone = form.getFieldValue('phone');
    const email = form.getFieldValue('email');
    const message = form.getFieldValue('message');

    const validated = first !== '' && last !== '' && phone !== '' && email !== '' && message !== '' ? false : true;
    setModalContactButton(validated);
  }

  return (
    <Modal
      type="primary"
      visible={modalVisible}
      onOk={onSendContactRequest}
      onCancel={() => onModalButtonClick()}
      centered
      okText={type === 'clinic' ? 'Request Consultation' : 'Contact Directly'}
      okButtonProps={{ disabled: modalContactButton }}
      title={name}
      keyboard
    >
      <ModalInner>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onFormValuesChange}
        >
          <Form.Item label="First Name" required name={['firstname']}>
            <Input placeholder="First Name" />
          </Form.Item>
          <Form.Item label="Last Name" required name='lastname'>
            <Input placeholder="Last Name" />
          </Form.Item>
          <Form.Item label="Phone Number" required name='phone'>
            <Input placeholder="Your Phone Number: 111-111-1111" />
          </Form.Item>
          <Form.Item label="Email" required name='email'>
            <Input placeholder="Your Email: you@something.com" />
          </Form.Item>
          <Form.Item label="Message" required name='message'>
            <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} showCount />
          </Form.Item>
        </Form>
      </ModalInner>
    </Modal>
  )
}

const ModalInner = styled.div``;

export default MapModal;