import React, { useState, useEffect, useContext } from 'react';
import { Context } from '../../state/store.state';
import styled from 'styled-components';
import globalStyles from '../../assets/style/global.style';
import { Select, Button, Input, Form } from 'antd';

import Icon from '../../components/icon/icon.component';
import Text from '../../components/text/text.component';
import SearchHeader from './search-header.container';
import { gender, stage, type, puberty, services } from './search-madlibs-service.data';
import CurrentLocationInput from '../../components/current-location-input/current-location-input.component';
import { filterServices } from '../../util/services.util';

const { Option } = Select;

const SearchMadlibs = (props) => {

  const {
    goMap,
    isMobileWidth,
  } = props;

  const [form] = Form.useForm();
  const [globalState, dispatch] = useContext(Context);
  const [servicesList, setServicesList] = useState(services);
  const [selectedServices, setSelectedServices] = useState([]);
  const [notFound, setNotFound] = useState('');
  const [isSearchDisabled, setIsSearchDisabled] = useState(true);

  useEffect(() => {
    (!globalState.geolocation) ? setIsSearchDisabled(true) : setIsSearchDisabled(false);
  }, [])

  const onFormChange = () => {
    const values = form.getFieldsValue();
    let servicesListClone = [...services];

    if (values.gender !== undefined) {
      switch(values.gender) {
        case 'male':
          servicesListClone = servicesListClone.filter(service => service.gender !== 'female');
          break;
        case 'female':
          servicesListClone = servicesListClone.filter(service => service.gender !== 'male');
          break;
        default:
          servicesListClone = servicesListClone;
          break;
      }
    }

    if (values.stage !== undefined) {
      switch(values.stage) {
        case 'pre-treatment':
          servicesListClone = servicesListClone.filter(service => service.stage !== 'post-treatment');
          break;
        case 'post-treatment':
          servicesListClone = servicesListClone.filter(service => service.stage !== 'pre-treatment');
          break;
        default:
          servicesListClone = servicesListClone;
          break;
      }
    }

    if (values.type !== undefined) {
      switch (values.type) {
        case 'preservation':
          servicesListClone = servicesListClone.filter(service => service.type !== 'bulding');
          break;
        case 'bulding':
          servicesListClone = servicesListClone.filter(service => service.type !== 'preservation');
          break;
        default:
          servicesListClone = servicesListClone;
          break;
      }
    }

    if (values.puberty !== undefined) {
      switch (values.puberty) {
        case 'have':
          servicesListClone = servicesListClone.filter(service => service.puberty !== 'have-not');
          break;
        case 'have-not':
          servicesListClone = servicesListClone.filter(service => service.puberty !== 'have');
          break;
        default:
          servicesListClone = servicesListClone;
          break;
      }
    }
    
    setServicesList(servicesListClone);
    
    if (values.value !== undefined) {
      setSelectedServices(values.value);
    }
  }

  const onSearch = () => {
    let cards = globalState.data;

    if (selectedServices.length) cards = filterServices(selectedServices, cards);

    if (cards.length < 1) {
      setNotFound('No services found please try a different selection');
    } else {
      dispatch({ type: 'SET_CARDS', payload: cards });
      goMap();
    }
  }

  const setGeolocation = (coords) => {
    dispatch({
      type: 'SET_GEOLOCATION',
      payload: coords
    });

    setIsSearchDisabled(false);
  }

  return (
    <SearchMadlibsContainer>
      <SearchHeader {...props} type={'madlibs'}/>
      <Madlibs>
        <MadlibsHeader>
          <Text fontWeight='700' fontSize='1.7rem' mobileFontSize='1.2rem' textAlign='left'>
            We want to help you find the services you need.
          </Text>
          <Text fontWeight='700' fontSize='1.7rem' mobileFontSize='1.2rem' textAlign='left'>
            Let’s start with a few questions about you:
          </Text>
          <Text fontWeight='500' fontSize='.9rem' mobileFontSize='.8rem' textAlign='left'>(providers, or family members, please answer of behalf of your patient)</Text>
          <Text fontWeight='500' fontSize='.9rem' mobileFontSize='.8rem' textAlign='left' fontStyle='italic'>
            Please note: It is not necessary to fill out all fields in the selection tool. Location is the only required field.
          </Text>
        </MadlibsHeader>

        <Form form={form} onValuesChange={onFormChange} 
          style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%'}}
        >
          <Text fontSize='1.2rem' mobileFontSize='.9rem' padding='10px 0 0 0' fontWeight='500' textAlign='left'>
            <FormItemInline>
              I am in need of
              <Form.Item name='gender' style={{padding: `${ isMobileWidth ? '0 0px' : '0 5px'}`}}>
                <Select
                  bordered={false}
                  dropdownMatchSelectWidth 
                  style={{ borderBottom: '1px solid black' }}
                  placeholder='Select Gender'
                  dropdownStyle={{ color: 'black' }}
                  dropdownMatchSelectWidth={200}
                >
                  {gender.map(gender =>
                    <Option value={gender.value}>
                      <Text fontSize='1rem'>{gender.label}</Text>
                    </Option>
                  )}
                </Select>
              </Form.Item>
              specific services.
            </FormItemInline>
          </Text>

            <Text fontSize='1.2rem' mobileFontSize='.9rem' padding='10px 0 0 0' fontWeight='500' textAlign='left'>
              <FormItemInline>
                I am in the
                <Form.Item name='stage' style={{ padding: `${isMobileWidth ? '0 0px' : '0 5px'}` }}>
                  <Select
                    bordered={false}
                    dropdownMatchSelectWidth style={{ borderBottom: '1px solid black' }}
                    placeholder='Select Stage'
                    dropdownMatchSelectWidth={200}
                  >
                    {stage.map(stage =>
                      <Option value={stage.value}>
                        <Text fontSize='1rem'>
                          {stage.label}
                        </Text>
                      </Option>
                    )}
                  </Select>
                </Form.Item>
                stage of treatment,
              </FormItemInline>
            </Text>

            <Text fontSize='1.2rem' mobileFontSize='.9rem' padding='10px 0 0 0' fontWeight='500' textAlign='left'>
              <FormItemInline>
                looking for
                <Form.Item name='type' style={{ padding: `${isMobileWidth ? '0 0px' : '0 5px'}`}} >
                  <Select
                    bordered={false}
                    dropdownMatchSelectWidth style={{ borderBottom: '1px solid black' }}
                    placeholder="Preservation or Family Building"
                    dropdownMatchSelectWidth={200}
                  >
                    {type.map(type =>
                      <Option value={type.value}>
                        <Text fontSize='1rem'>
                          {type.label}
                        </Text>
                      </Option>
                    )}
                  </Select>
                </Form.Item>
              </FormItemInline>
            </Text>

            <Text fontSize='1.2rem' mobileFontSize='.9rem' padding='10px 0 0 0' fontWeight='500' textAlign='left'>
              <FormItemInline>
                <Text mobileMinWidth='40px'>and I</Text>
                <Form.Item name='puberty' style={{ padding: '0 0px' }}>
                  <Select
                    bordered={false}
                    dropdownMatchSelectWidth style={{ borderBottom: '1px solid black' }}
                    placeholder='Select Puberty Status'
                    dropdownMatchSelectWidth={200}
                  >
                    {puberty.map(puberty =>
                      <Option value={puberty.value}>
                        <Text fontSize='1rem'>
                          {puberty.label}
                        </Text>
                      </Option>
                    )}
                  </Select>
                </Form.Item>
                gone through puberty.
              </FormItemInline>
            </Text>

            <FormItemInline style={{padding: '10px 0'}}>
            <Text fontSize='1.2rem' mobileFontSize='.9rem' fontWeight='500' minWidth='140px' textAlign='left' mobileMinWidth='110px'>
              <Text padding='0 5px'> I am located at</Text>
              </Text>
                <CurrentLocationInput
                  padding='0 5px'
                  setGeolocation={setGeolocation}
                  prop_placeholder='Set location'
                  minWidth='200px'
                />
              <Text fontSize='1.2rem' mobileFontSize='.9rem' fontWeight='500' minWidth='50px'>, and</Text>
            </FormItemInline>

            <Text fontSize='1.2rem' mobileFontSize='.9rem' padding='10px 0 20px 0' fontWeight='500' textAlign='left'>
              <FormItemInline>
              I am looking for
                  <Form.Item name='value' style={{ padding: '0 5px' }}>
                    <Select
                      mode='multiple'
                      bordered={false}
                      dropdownMatchSelectWidth
                      style={{ minWidth: '200px', borderBottom: '1px solid black' }}
                      placeholder="Select Service"
                      dropdownMatchSelectWidth={300}
                    >
                      {servicesList.map((service, index) =>
                        <Option value={service.value} key={index}>
                          <Text fontSize='1rem'>
                            {service.label}
                          </Text>
                        </Option>
                      )}
                    </Select>
                  </Form.Item>
                services.
              </FormItemInline>
            </Text>
        </Form>

        <Button
          type='default'
          shape='round'
          size='large'
          block
          style={{ backgroundColor: globalStyles.color.afpGreen, fontWeight: 'bold', marginTop: '10px' }}
          icon={<Icon type='search' color='white' fontSize='1.1rem' />}
          onClick={onSearch}
          disabled={isSearchDisabled}
        >
          <Text color='white' fontWeight='700' padding='0 0 0 5px'>
            {isSearchDisabled ? 'Location required' : 'Search'
          }</Text>
        </Button>
        <Text color={'red'}>{notFound}</Text>
        <Text fontSize='.9rem' padding='10px 0 20px 0' fontWeight='500' height='240px' mobileFontSize='.8rem'> The information and services provided by the Alliance for Fertility Preservation are for informational purposes only. The information and services listed here are not intended to be substitutes for professional medical advice, diagnosis, or treatment. If you are ill, please seek medical attention immediately. The Alliance for Fertility Preservation does not recommend or endorse any specific physicians, professionals, treatments, or products even though they may be mentioned on this site.</Text>
      </Madlibs>
    </SearchMadlibsContainer>
  )
}

export default SearchMadlibs;

const SearchMadlibsContainer = styled.section`
  padding: 20px 18%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${globalStyles.breakpoints.l_desktop} {
    padding: 20px 10%;
  }

  @media ${globalStyles.breakpoints.desktop} {
    padding: 20px 5%;
  }

  @media ${globalStyles.breakpoints.mobile} {
    min-width: 100vw;
    text-align: center;
  }
`;

const Madlibs = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${globalStyles.breakpoints.mobile} {
    padding: 0 10px;
    width: 100vw;
    text-align: center;
  }
`;

const MadlibsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;

  @media ${globalStyles.breakpoints.mobile} {
    padding: 0 10px 20px 10px;
    width: 100vw;
    text-align: center;
  }
`;

const FormItemInline = styled.div`
  display: flex;
  flex-direction: row;
  width: auto;

  @media ${globalStyles.breakpoints.mobile} {
    padding: 0 10px;
    width: 100vw;
    text-align: center;
  }
`;